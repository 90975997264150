import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import LoginPage from '../pages/LoginPage/LoginPage';
import InputLabel from '@material-ui/core/InputLabel';
import HiringFormStatic from '../pages/HiringForm_Static';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "../common/styles/HiringStaticPage.css";
const theme = createMuiTheme({
    overrides: {
        MuiAutocomplete: {
            inputRoot: {
                '&&[class*="MuiFilledInput-root"] $input': {
                    padding: "0 !important",
                },
                height: "55px !important",
                background: "#E8F8F8",
                '&$disabled': {
                    backgroundColor: '#454545 !important',
                },
            }
        },
        MuiFilledInput: {
            root: {
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#000028",
                    backgroundColor: "#E8F8F8",
                },
                "&&&&:hover": {
                    backgroundColor: "#E8F8F8"
                },
                "&$disabled": {
                    "pointerEvent": "none",
                    "color": "#707080",
                    "backgroundColor": "#001035",
                    "border": "2px solid #707070",
                    "borderRadius": "0",
                    "&:before": {
                        "borderBottom": "1px solid #001035",
                    },
                    "&:hover:before": {
                        "borderBottom": "1px solid #001035",
                        "borderBottomStyle": "solid"
                    }
                }
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #E8F8F8",
                    transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:after": {
                    borderBottom: "2px solid #E8F8F8",
                    transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC"
                },
                "&:hover:after": {
                    borderBottom: "2px solid #6CD6D6"
                },
                "&:focused:after": {
                    borderBottom: "2px solid #6CD6D6"
                },
                "&:disabled:before": {
                    border: "none"
                }
            },
        },

        MuiButtonBase: {
            root: {
                background: "#E8F8F8",
                '&$disabled': {
                    backgroundColor: '#454545 !important',
                },
            },
        },
        MuiDialogActions: {
            root: {
                background: "#00CCCC"
            }
        },
        MuiDialogTitle: {
            root: {
                padding: "8px 24px",
            },
        },
        //MuiTextField: {
        //    root: {
        //        color: "#002949",
        //        background: "#000028",
        //        width: '100%'
        //    },
        //},
        MuiFormLabel: {
            root: {
                //color: "#B3B3BE",
                color: "#000028",
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            }
        },
        MuiTextField: {
            root: {
                color: "#000028",
                background: "#00CCCC",
                width: '100%'
            },
        },
        MuiInputLabel: { // Name of the component ⚛️ / style sheet
            root: { // Name of the rule
                //color: "#B3B3BE",
                color: "#4C4C68",
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#00CCCC"
                }
            },
            asterisk: {
                color: "red"
            }
        },
        MuiInputBase: {
            root: {
                color: "#4C4C68"
            },
            input: {
                backgroundColor: "#EBFBFB",
                color: "#4C4C68",
                borderBottom: "0px solid #707070",
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#E0F9F9",
                    backgroundColor: "#CCF5F5",
                },
                "&$hover": {

                    backgroundColor: "#CCF5F5",
                }
            }
        },
        MuiInput: {
            root: {
                height: "56px",
                backgroundColor: "#E8F8F8",
                color: "#000028",
                paddingLeft: "0px",
                "&$focused": {
                    backgroundColor: "#E8F8F8",
                }
            },
            underline: {
                "&:before": {
                    borderBottom: "0px solid #FFFFFF",
                    transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:after": {
                    borderBottom: "2px solid #FFFFFF",
                    transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:hover:before": {
                    borderBottom: "2px solid #FFFFFF"
                },
                "&:hover:after": {
                    borderBottom: "2px solid #FFFFFF"
                },
                "&:focused:after": {
                    borderBottom: "2px solid  #FFFFFF"
                },
                "&:disabled:before": {
                    border: "none"
                }
            },
        },
        MuiSelect: {
            root: {
                backgroundColor: "#E8F8F8",
                color: "#000028"
            }
        },
        MuiFormControl: {
            root: {
                color: "#FFFFFF",
                width: "100",
                paddingLeft: "0px"
            }
        },
        MuiList: {
            root: {
                backgroundColor: "#CCF5F5"
            }
        },
        MuiListItem: {
            root: {
                color: "#FFFFFF",
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: "20px",
                marginBottom: "20px",
                background: "#CCF5F5"
            }
        },

        MuiToolbar: {
            root: {
                background: "#000028",
                color: "#FFFFFF"
            }
        },
        MuiTablePagination: {
            root: {
                borderBottom: "none"
            },
            toolbar: {
                borderTop: `1px solid #FFFFFF`,
                "& MuiTypography-root": {
                    color: `#00CCCC !important`
                }
            },
            menuItem: {
                color: "#FFFFFF"
            },
            selectIcon: {
                color: "#00CCCC"
            },
            input: {
                backgroundColor: "#000028"
            }
        },
        MuiTypography: {
            caption: {
                color: "#000028"
            }
        },
        MuiTableSortLabel: {
            root: {
                color: "#00CCCC",
                "&$focused": {
                    color: "#00CCCC",
                },
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
                "& div:hover": {
                    color: "#00CCCC"
                }
            },
            icon: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
            },
            iconDirectionDesc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
            },
            iconDirectionAsc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
            }
        },
        MuiTableCell: {
            root: {
                color: "#FFFFFF !important"
            },
            body: {
                background: "#000028",
                color: "#FFFFFF"
            }
        },
        //MuiFormControl: {
        //    root: {
        //        paddingLeft: "0px"
        //    }
        //},
        MuiSvgIcon: {
            root: {
                color: "#6a7272"
            }
        },
        MuiPickersBasePicker: {
            pickerView: {
                background: "#000028"
            },
            background: "#000028",
            container: {
                background: "#000028"
            }
        },
        MuiPickersDatePickerRoot: {
            toolbar: {
                background: "#000028"
            }
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: "none",
                color: "#FFFFFF"
            },
            switchHeader: {
                color: "#FFFFFF"
            },
            daysHeader: {
                color: "#FFFFFF"
            },
            dayLabel: {
                color: "#FFFFFF"
            }
        },
        MuiPickersYear: {
            root: {
                color: "#FFFFFF"
            }
        },
        //MuiPickersBasePicker: {
        //    container: {
        //        background: "#000028"
        //    }
        //},
        MuiPickersDay: {
            day: {
                color: "#FFFFFF"
            }
        },
        MuiButton: {
            textPrimary: {
                color: "#FFFFFF"
            }
        },
        MuiTable: {
            root: {
                backgroundColor: "#000028"
            }
        },
        MuiFormHelperText: {
            contained: {
                backgroundColor: "white",
                marginBottom: "-10px !important",
                marginLeft: "0px !important",
                marginRight: "0px !important",
                color: "red !important",
                lineHeight: "0.5 !important"
            },
        },
    }
});

export default function PrivacyNotes(props) {
    const [open, setOpen] = React.useState(false);
    //const [scroll, setScroll] = React.useState('paper');
    const [isConsentAgree, setisConsentAgree] = React.useState(false);
    //var [Consent, setConsent] = React.useState([]);
    //const [ConsentID, setConsentID] = React.useState();
    const [isConsentCloseButtonClicked, setConsentCloseButtonClicked] = React.useState(false);
    //const [numPages, setNumPages] = React.useState(null);
    //const [pageNumber, setPageNumber] = React.useState(1);

    const handleClose = () => {
        // window.location.href = 'http://localhost:5000/Login/logout';
        //window.location.href = props.url + "/Login/logout";//'https://inmumthaiaasnew.ad001.siemens.net:444/Login/logout';
        // window.location.href = 'Login/logout';//'https://inmumthaiaasnew.ad001.siemens.net:444/Login/logout';
        setConsentCloseButtonClicked(true);
        setOpen(false);
        window.location.href = '/';
    };

    const handleAccept = async () => {
        await AcceptConsent();
        setOpen(false);
        setisConsentAgree(true);
        //debugger;        
    };

    const AcceptConsent = async () => {
        const data = await fetch('Login/SubmitConsent/' + props.loginDetails[0].username);
        await data.json();
        //var empData = await data.json();
        // setDataemp(empData.plainConsent);

        //if (empData.iss == true) {
        //    setOpen(false);
        //}
        //else {
        //    setOpen(false);
        //}
    }
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        FetchData();
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const FetchData = async () => {
        // const data = await fetch('EmployeeDetails/' + props.employeeID);

        //const data = await fetch(props.url + "/Login/LoadConsent/" + props.loginDetails[0].username);
        const data = await fetch('Login/LoadConsent/' + props.loginDetails[0].username);
        let Consent = await data.json();

        if (Consent.isSubmitted == null) {
            setisConsentAgree(false);
            //setConsent(Consent.plainConsent);
            // setConsentID(Consent.id);
            setOpen(true)
        }
        else {
            setisConsentAgree(true);
        }
        //console.log(Consent.id);
    }
    //const useStyles = makeStyles((theme) => ({
    //    formControl: {
    //        minWidth: "100%",
    //        color: "#00CCCCC",
    //        background: "#FFFFFF"
    //    },

    //}));

    if (isConsentAgree) {
        var routes = (
            <Switch>
                <Route exact path="/HiringFormStatic" render={() => <HiringFormStatic />} />
                <Redirect from="/Consent" to="/HiringFormStatic" />
            </Switch>
        );
        return (
            <BrowserRouter>
                <div>
                    <main className="">{routes}</main>
                </div>
            </BrowserRouter>
        );
    }
    else if (isConsentCloseButtonClicked) {
        var routesLogin = (
            <Switch>
                <Route exact path="/LoginPage" render={() => <LoginPage
                    loginChangeEvent={props.loginChangeEvent}
                    loginClick={props.loginClick}


                />} />
                <Redirect from="/" to="/LoginPage" />
            </Switch>
        );
        return (
            <BrowserRouter>
                <div>
                    <main className="">{routesLogin}</main>
                </div>
            </BrowserRouter>
        );
    }
    else {
        return (
            <ThemeProvider theme={theme}>
                <div className="master-layout-block">
                    <Box className="Box-padding">
                        <h3 className="clr-dark-blue d-flex jc-center">Privacy Notice</h3>
                    </Box>
                    <form className="d-flex jc-center">
                        <Grid container item xs={12} className="d-flex fd-column box-shadow px-2 mb-2 bdr-radius ">
                            <Grid container className="d-flex jc-space-around fd-row">
                                <Grid item xs={12} sm={12} className="d-flex ">
                                    <InputLabel id="demo-simple-select-label" className="mt-1 lightInputFields"><b>Last Update 19/07/2021 </b></InputLabel>
                                </Grid>


                                <Grid item xs={12} sm={12} className="d-flex mt-1">
                                    Protecting the security and privacy of your personal data is important to Siemens. Therefore Siemens operates the STA India Admission Form in compliance with applicable laws on data protection and data security.
                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex mt-1">
                                    <b>Categories of personal data processed and purpose of the processing</b>
                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex ">
                                    When visiting and using the App, Siemens processes the following personal data about you:
                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex">
                                    Personal data that you actively provide to us within the Form i.e. information that you actively enter when filling-up the Form
                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex mt-1">
                                    <b>Full Name, Contact Number, Photograph, Year of Birth, Gender, E-Mail ID, Family Details, Residential Address, Educational Qualifications & Details</b>
                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex mt-1">
                                    We process your personal data for the following purposes:
                                </Grid>

                                <Grid item xs={12} sm={12} spacing={3} className="d-flex mt-1">
                                    <b>To facilitate the application process for apprenticeship 2024
                                  </b>
                                </Grid>

                                <Grid item xs={12} sm={12} spacing={3} className="d-flex">
                                    <b>
                                        To check eligibility of candidates for the apprenticeship 2024
                                   </b>
                                </Grid>
                                <Grid item xs={12} sm={12} spacing={3} className="d-flex">
                                    <b>
                                        To conduct background check & verification
                                   </b>
                                </Grid>
                                <Grid item xs={12} sm={12} spacing={3} className="d-flex">
                                    <b>
                                        To perform such other activities required for apprenticeship 2024</b>
                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex mt-1">
                                    <b>Push notifications</b>
                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex mt-1">
                                    This form does not have any push notifications.
                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex mt-1">
                                    <b>Links</b>
                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex mt-1">
                                    This form does not contain any links to any other websites, webpages or internet / intranet sites.
                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex mt-1 ">
                                    <b>Cookies</b>
                                </Grid>
                                <Grid item xs={12} sm={12} className="d-flex mt-1">
                                    This form does not collect any cookie information.
                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex mt-1 ">
                                    <b>Location</b>

                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex mt-1">

                                    This form does not contain any location related information.
                                </Grid>



                                <Grid item xs={12} sm={12} className="d-flex mt-1 ">
                                    <b>Machine / Device Data</b>

                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex mt-1">

                                    This form does not collect any machine / device data e.g., IP Address, MAC Address, Computer Name, Device Information, etc. Further, this form will not access any information / data within the machine / device e.g. folders, drives, camera, contacts, image gallery, videos, etc.
                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex mt-1 ">
                                    <b>Transfer and disclosure of personal data</b>
                                </Grid>


                                <Grid item xs={12} sm={12} className="d-flex mt-1 ">
                                    For the purposes mentioned above Siemens may transfer or disclose your personal data to:
                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex">


                                    <ul>
                                        <li>Implementing Partner engaged by Siemens for the Corporate Social Responsibility (CSR) activities.</li>
                                        <li>Courts, law enforcement authorities, regulators or attorneys if necessary to comply with the law or for the establishment, exercise or defense of legal claims.</li>
                                        <li>Service providers (so-called data processors), such as hosting or IT maintenance service providers, which only act upon instructions of Siemens and are contractually bound to act in compliance with applicable data protection law.</li>
                                    </ul>
                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex mt-1 ">
                                    <b>Retention periods</b>

                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex mt-1 ">

                                    Unless indicated otherwise at the time of the collection of your personal data (e.g. within a form completed by you),
                                    we erase your personal data if the retention of that personal data is no longer necessary (i) for the purposes for
                                    which they were collected or otherwise processed, or (ii) to comply with legal obligations.
                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex mt-1 ">
                                    <b>Your rights</b>

                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex mt-1 ">

                                    The data protection law applicable to Siemens when processing your personal data may entitle you to specific
                                    rights in relation to your personal data. You can find out more about those rights by contacting
                                    dataprotection@siemens.com.
                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex mt-1 ">
                                    <b>Data Privacy Contact</b>

                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex mt-1 ">

                                    The Siemens Data Privacy Organization provides support with any data privacy related questions, comments,
                                    concerns or complaints or in case you wish to exercise any of your data privacy related rights. The Siemens Data
                                    Privacy Organization may be contacted at dataprotection@siemens.com.
                                    </Grid>
                                <Grid item xs={12} sm={12} className="d-flex mt-1 ">
                                    The Siemens Data Privacy Organization will always use best efforts to address and settle any requests or
                                    complaints you bring to its attention. Besides contacting the Siemens Data Privacy Organization, you always have
                                    the right to approach the competent data protection authority with your request or complaint.
                                </Grid>

                                <Grid item xs={12} sm={12} className="d-flex mt-1 ">
                                    <b>Contact details of Data Privacy Organization of Siemens Limited:</b>
                                </Grid>
                                <Grid item xs={12} sm={12} className="d-flex mt-1 ">
                                    <b><u>Data Privacy Officer</u></b>
                                </Grid>
                                <Grid item xs={12} sm={12} className="d-flex mt-1 ">
                                    <b>Mr. Sankar Balasubramanian </b>
                                </Grid>
                                <Grid item xs={12} sm={12} className="d-flex mt-1 ">
                                    E-mail ID: sankar.balasubramanian@siemens.com
                                </Grid>
                                <Grid item xs={12} sm={12} className="d-flex mt-1 ">

                                    <b><u>Data Privacy Advisors</u></b>
                                </Grid>
                                <Grid item xs={12} sm={12} className="d-flex mt-1 ">
                                    1. Rohan Desai
                                    </Grid>
                                <Grid item xs={12} sm={12} className="d-flex mt-1 ">
                                    E-Mail ID: rohan.desai@siemens.com
                                </Grid>
                                <Grid item xs={12} sm={12} className="d-flex mt-1 ">
                                    2. Srinivasan Thirumurthi
                                    </Grid>
                                <Grid item xs={12} sm={12} className="d-flex mt-1 ">
                                    E-Mail ID: t.srinivasan@siemens.com


                                </Grid>





                                <Grid container spacing={2} className="d-flex jc-space-around fd-row mt-1 mb-1">
                                    <Grid item xs={12} sm={12} className="privacyBtn">
                                        <Button className="ns-btn-primary ml-1 mr-1 mb-1 privacyBtn" onClick={handleAccept}>
                                            <span>I Agree</span>
                                        </Button>
                                        <Button className="ns-btn-primary ml-1 mr-1 mb-1" onClick={handleClose}>
                                            <span>Cancel</span>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </form>
                </div>

            </ThemeProvider>
        );
    }
}
