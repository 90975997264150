import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import LoginPage from './pages/LoginPage/LoginPage';
import AppHeader from './pages/AppHeader';
import UnauthorizedAccess from './pages/UnauthorizedAccess';
import OTPpage from './pages/OTPpage';
import TimeTicker from './pages/TimeTicker';
import ScrollToTop from './pages/components/ScrollToTop';
import Consent from './pages/PrivacyNotes';
import "./common/styles/appbar.css";
import "./common/styles/common.css";
import "./common/styles/drawer.css";
import "./common/styles/component.css";
import "./common/styles/mobile.css";
import "./common/styles/fonts.css";
import HiringForm from './pages/HiringForm';
import HiringFormStatic from './pages/HiringForm_Static';

export default class App extends Component {
    saveStateToLocalStorage() {
        for (let key in this.state) {
            localStorage.setItem(key, JSON.stringify(this.state[key]));
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            loginDetails: [
                {
                    username: '', password: '', userid: '', userfirstname: '', userlastname: ''
                    , isOTPEnabled: false, otp: '', roleId: '', loading: false, applicantid: '',
                    LoginFlag: '', redirectedto: '', forgetPassword: false, authToken: '', AREId: ''
                }
            ],
            otpDetails: [
                {
                    otpEntry: '', otpGenerated: '', isOTPVerified: false, otpAlertText: '', buttontext: '', forgetPassword: false, otpSent: false
                }
            ],
            rememberMeDetails: [
                {
                    rememberMeIsChecked: false, username: '', password: ''
                }
            ],
            isLoading: false,
        };
        //const [openAlert, setOpenAlert] = React.useState(false);
        //const [openAlertDesc, setOpenAlertDesc] = React.useState("");
        //const handleClose = () => {
        //    setOpen(false);
        //};
        //const handleCloseAlert = () => {
        //    setOpenAlert(false);
        //};
        //this.idleTimer = null
        //this.handleOnIdle = this.handleOnIdle.bind(this)
        this.initState = {
            loginDetails: [
                {
                    username: '', password: '', userid: '', userfirstname: '', userlastname: ''
                    , isOTPEnabled: false, otp: '', roleId: '', loading: false, applicantid: '',
                    LoginFlag: '', redirectedto: '', forgetPassword: false, authToken: '', AREId: ''
                },
            ],
            otpDetails: [
                {
                    otpEntry: '', otpGenerated: '', isOTPVerified: false, otpAlertText: '', buttontext: '', forgetPassword: false, otpSent: false
                },
            ],
            rememberMeDetails: [
                {
                    rememberMeIsChecked: false, username: '', password: ''
                }
            ],
            isLoading: false,
        };
        this.timeout = 600;
        this.eventHandler = this.updateExpiredTime.bind(this);
        this.tracker();
        this.startInterval();
    }

    startInterval() {
        this.updateExpiredTime();
        this.interval = setInterval(() => {
            const expiredTime = parseInt(localStorage.getItem("_expiredTime"), 10);
            if (expiredTime < Date.now()) {
                this.cleanUpTimeout();
                this.clickEventLogout();
            }
        }, 1000);
    }
    updateExpiredTime() {
        localStorage.setItem("_expiredTime", Date.now() + this.timeout * 1000);
    }
    tracker() {
        window.addEventListener("mousemove", this.eventHandler);
        window.addEventListener("scroll", this.eventHandler);
        window.addEventListener("keydown", this.eventHandler);
    }
    cleanUpTimeout() {
        localStorage.removeItem("_expiredTime");
        clearInterval(this.interval);
        window.removeEventListener("mousemove", this.eventHandler);
        window.removeEventListener("scroll", this.eventHandler);
        window.removeEventListener("keydown", this.eventHandler);
    }
    componentDidMount() {
        const loginDetails = this.state.loginDetails.map((c) => {
            // c.rememberMeIsChecked = localStorage.IsChecked;
            c.username = localStorage.username;
            //c.isOTPEnabled=
            // c.password = localStorage.Password;
            return c;
        });
        this.setState({ loginDetails });
        if (localStorage.IsChecked && localStorage.UserName !== '') {
            const rememberMeDetails = this.state.rememberMeDetails.map((c) => {
                c.rememberMeIsChecked = localStorage.IsChecked;
                c.username = localStorage.UserName;
                c.password = localStorage.Password;
                return c;
            });
            this.setState({ rememberMeDetails });
        }
        else {
            const rememberMeDetails = this.state.rememberMeDetails.map((c) => {
                c.rememberMeIsChecked = false;
                c.username = '';
                c.password = '';
                return c;
            });
            this.setState({ rememberMeDetails });
        }
        this.startInterval();
    }

    RedirectToUnauthorized() {
        const loginDetails = [...this.state.loginDetails];
        //const otpDetails = [...this.state.otpDetails];

        const taPages = ['ResetPassword', 'CandidateData', 'CandidateRegistration', 'ApplicationForm', 'PreviewApplicationForm', 'DownloadDocuments'];
        const candidatePages = ['ResetPassword', 'ApplicationForm', 'PreviewApplicationForm'];
        let currentURL = window.location.href;
        var arrURL = currentURL.split('/');
        if (loginDetails[0].LoginFlag == 1 && loginDetails[0].username != '') {
            //if (loginDetails[0].roleId == 1) {

            //}
            //else 
            if (loginDetails[0].roleId == 2 || loginDetails[0].roleId == 4) {
                console.log(arrURL[3]);
                console.log(taPages);
                if (!taPages.includes(arrURL[3])) {
                    window.location.href = '/UnauthorizedAccess';
                }
                //else {
                //}
            }
            else if (loginDetails[0].roleId == 3) {
                if (!candidatePages.includes(arrURL[3])) {
                    window.location.href = '/UnauthorizedAccess';
                }
                //else {
                //}
            }
        }
    }

    //changeEventRememberMe(value) {
    //    if (value) {
    //        const rememberMeDetails = this.state.rememberMeDetails.map((c) => {
    //            c.rememberMeIsChecked = value;
    //            return c;
    //        });
    //        this.setState({ rememberMeDetails });
    //    }
    //    else {
    //        const rememberMeDetails = this.state.rememberMeDetails.map((c) => {
    //            c.rememberMeIsChecked = false;
    //            c.username = '';
    //            c.password = '';
    //            return c;
    //        });
    //        this.setState({ rememberMeDetails });
    //    }
    //}

    changeEventTextFieldLogin(fieldName, value) {
        if (fieldName == "username") {
            const loginDetails = this.state.loginDetails.map((c) => {
                c.username = value;
                return c;
            });
            this.setState({ loginDetails });
        }
        else if (fieldName == "password") {
            const loginDetails = this.state.loginDetails.map((c) => {
                c.password = value;
                return c;
            });
            this.setState({ loginDetails });
        }
    }

    //async GetUser() {
    //    const options = {
    //        method: 'get',
    //        headers: {
    //            'Accept': 'application/json, text/plain, */*',
    //            'Content-Type': 'application/json'
    //        },
    //    }
    //    await fetch('Login/GetUser', options)
    //        .then(response => response.json())
    //        .then(data => {
    //            if (data.userId != null && data.userId != '') {
    //                let loginDetails = this.state.loginDetails.map((c) => {
    //                    c.userid = data.userId;
    //                    c.applicantid = data.applicantId;
    //                    c.roleId = data.roleId;
    //                    c.AREId = data.companyId;
    //                    c.username = data.userName;
    //                    c.userfirstname = data.firstName;
    //                    c.userlastname = data.lastName;
    //                    c.LoginFlag = data.loginFlag;
    //                    c.isOTPEnabled = false;
    //                    c.authToken = data.responseMsg;
    //                    return c;
    //                });
    //                this.setState({ loginDetails });
    //                let otpDetails = this.state.otpDetails.map((c) => {
    //                    c.isOTPVerified = true;
    //                    return c;
    //                });
    //                this.setState({ otpDetails });
    //            }
    //            else {
    //                let loginDetails = [...this.initState.loginDetails];
    //                this.setState({ loginDetails });
    //                let otpDetails = [...this.initState.otpDetails];
    //                this.setState({ otpDetails });
    //            }
    //        }).catch(error => console.log("Error:", error));
    //}






    clickEventLogin() {

        //this.setState({ isLoading: true });
        let loginDetails = this.state.loginDetails;

        var EmailId = loginDetails[0].username;
        // if (EmailId === undefined || EmailId === "" || !this.validateEmail(EmailId)) {

        //     alert("Invalid email");
        //     return false;
        // }
        //else
        // {

        const formData = new FormData();
        formData.append('UserName', EmailId);
        const options = {
            method: 'post',
            //headers: {
            //    'Accept': 'application/json, text/plain, */*',
            //    'Content-Type': 'application/json'
            //},

            body: formData
        }
        fetch('Login/LoginUserOTP', options)
            .then(response => response.json())
            .then(data => {

                //console.log(data);
                //if (data.isAlreadyRegistered == true)
                if (data.isAlreadyRegistered) {
                    //alert("You have been already registered!!");
                    // this.setState({ isLoading: false });
                }
                else {
                    const otpDetails = this.state.otpDetails.map((c) => {
                        c.otpGenerated = data.otp;
                        c.otpSent = true;
                        return c;
                    });
                    this.setState({ otpDetails });

                    loginDetails = this.state.loginDetails.map(c => {
                        c.isOTPEnabled = true;
                        return c;
                    });
                    this.setState({ loginDetails });
                }
            }).catch(error => {
                console.log("Error:", error)
                this.setState({ isLoading: false });
            });
    }

    changeEventTextFieldOTP(value) {
        const otpDetails = this.state.otpDetails.map((c) => {
            c.otpEntry = value;
            c.otpSent = false;
            return c;
        })
        this.setState({ otpDetails });
    }
    clickEventVerifyOTP() {
        this.setState({ isLoading: true });
        let otpDetails = this.state.otpDetails;
        //let loginDetails = this.state.loginDetails;
        //const token = loginDetails[0].authToken;
        if (otpDetails[0].otpEntry != "") {
            if (otpDetails[0].otpGenerated == otpDetails[0].otpEntry) {
                otpDetails = this.state.otpDetails.map((c) => {
                    c.isOTPVerified = true;
                    c.otpAlertText = '';
                    c.otpEntry = '';
                    c.otpGenerated = '';
                    c.otpSent = false;
                    return c;
                });
                this.setState({ otpDetails });
                const loginDetails = this.state.loginDetails.map((c) => {
                    c.isOTPEnabled = false;
                    c.password = '';
                    c.isOTPVerified = true;  //added by pooja
                    c.otp = '';
                    return c;
                });
                this.setState({ loginDetails });
                this.setState({ isLoading: false });
                console.log(loginDetails);
            }
            else {
                otpDetails = this.state.otpDetails.map((c) => {
                    c.otpAlertText = 'Please enter valid OTP or click on RESEND OTP';
                    c.otpSent = false;
                    //c.otpGenerated = '';
                    return c;
                });
                this.setState({ otpDetails });
                this.setState({ isLoading: false });
            }
        }
        else {
            otpDetails = this.state.otpDetails.map((c) => {
                c.otpAlertText = 'Please enter valid OTP and proceed';
                c.otpSent = false;
                c.otpGenerated = '';
                return c;
            });
            this.setState({ otpDetails });
            this.setState({ isLoading: false });
        }
    }

    clickEventResendOTP(loginDetails) {
        this.setState({ isLoading: true });
        //const token = loginDetails[0].authToken;
        var EmailId = loginDetails[0].username;
        const formData = new FormData();

        formData.append('UserName', EmailId);

        const options = {
            method: 'post',
            //headers: {
            //    'Accept': 'application/json, text/plain, */*',
            //    'Content-Type': 'application/json'
            //},

            body: formData
        }
        fetch('Login/LoginUserOTP', options)
            .then(response => response.json())
            .then(data => {
                //console.log(data);
                //if (data.isAlreadyRegistered == true) {
                if (data.isAlreadyRegistered) {
                    alert("You have been already registered!!");
                    // this.setState({ isLoading: false });
                }
                else {
                    const otpDetails = this.state.otpDetails.map((c) => {
                        c.otpGenerated = data.otp;
                        c.otpSent = true;
                        return c;
                    });
                    this.setState({ otpDetails });
                    this.setState({ isLoading: false });

                    //loginDetails = this.state.loginDetails.map(c => {
                    //    c.isOTPEnabled = true;
                    //    return c;
                    //});
                    //this.setState({ loginDetails });
                }
            }).catch(error => {
                console.log("Error:", error)
                this.setState({ isLoading: false });
            });
    }
    clickEventLogout() {
        //let loginDetails = this.state.loginDetails;
        //const token = loginDetails[0].authToken;
        const options = {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
            },
        }

        fetch('Login/Logout', options)
            .then(response => {
                if (response.ok) {
                    window.location.href = '/';
                }
                else {
                    alert('logout falied');
                }
            }).catch(error => {
                console.log("Error :" + error);
            });

    }
    clickEventForgetPassword() {
        this.setState({ isLoading: true });
        const loginDetails = this.state.loginDetails.map((c) => {
            c.forgetPassword = true;
            return c;
        });
        this.setState({ loginDetails });
        console.log(loginDetails);
        this.setState({ isLoading: false });
    }
    changeEventEmailFieldForgetPassword(value) {
        const loginDetails = this.state.loginDetails.map((c) => {
            c.username = value;
            return c;
        });
        this.setState({ loginDetails });
        console.log(loginDetails);
    }
    clickEventForgetPasswordSendOTP(loginDetails) {
        const userName = loginDetails[0].username;
        const forgetData = {
            'UserName': userName
        }
        const options = {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(forgetData)
        }
        fetch('Login/SendOTPForgetPassword', options)
            .then(response => response.json())
            .then(data => {
                if (data.isOTPSentForget && data.response) {
                    const loginDetails = this.state.loginDetails.map((c) => {
                        c.otp = data.otp;
                        c.isOTPEnabled = true;
                        c.userid = data.userId;
                        c.username = data.userName;
                        return c;
                    });
                    this.setState({ loginDetails });
                    console.log(loginDetails);
                    const otpDetails = this.state.otpDetails.map((c) => {
                        c.otpGenerated = data.otp;
                        c.otpSent = true;
                        c.forgetPassword = data.forgetFlag;
                        return c;
                    });
                    this.setState({ otpDetails });
                    this.setState({ isLoading: false });
                }
                else {
                    alert(data.responseMsg);
                    this.setState({ isLoading: false });
                }
            }).catch(error => {
                console.log("Error:", error);
                this.setState({ isLoading: false });
            });
    }


    render() {
        const loginDetails = [...this.state.loginDetails];
        const otpDetails = [...this.state.otpDetails];
        const rememberMeDetails = [...this.state.rememberMeDetails];
        const isLoading = this.state.isLoading;
        let contents = this.renderActualPage(loginDetails, otpDetails, rememberMeDetails, isLoading);
        return (
            <div>
                {contents}
            </div>
        );
    }

    renderActualPage(loginDetails, otpDetails, rememberMeDetails, isLoading) {
        //const taPages = ['UnauthorizedAccess', 'OTPPage', 'ResetPassword', 'CandidateData', 'CandidateRegistration', 'ApplicationForm', 'PreviewApplicationForm'];
        //const candidatePages = ['UnauthorizedAccess', 'OTPPage', 'ResetPassword', 'ApplicationForm', 'PreviewApplicationForm'];
        //let currentURL = window.location.href;
        //var arrURL = currentURL.split('/');
        const appHeader = <AppHeader
            loginDetails={loginDetails}
            otpDetails={otpDetails}
            logoutClick={this.clickEventLogout.bind(this)}
            roleId={loginDetails[0].roleId}
            loader={isLoading}
            token={loginDetails[0].authToken}
        />;
        const timeTicker = <TimeTicker
            loginDetails={loginDetails}
            eventHandler={this.updateExpiredTime.bind(this)}
        />;
        const scrollToTop = <ScrollToTop />;
        //console.log(loginDetails);
        if (loginDetails[0].isOTPEnabled) {
            console.log("OTP Enabled");
            localStorage.setItem("username", loginDetails[0].username);
            localStorage.setItem("isOTPEnabled", loginDetails[0].isOTPEnabled);
            var routes = (
                <Switch>
                    <Route exact path="/OTPPage"
                        render={() =>
                            <OTPpage
                                loginDetails={loginDetails}
                                otpDetails={otpDetails}
                                otpChangeEvent={this.changeEventTextFieldOTP.bind(this)}
                                otpClickVerify={this.clickEventVerifyOTP.bind(this)}
                                otpClickResend={this.clickEventResendOTP.bind(this)}
                                url={"http://localhost:54008/OTPPage"}
                                loader={isLoading}
                                token={loginDetails[0].authToken}
                            />
                        }
                    />
                    <Redirect from="/" to="/OTPPage" />
                </Switch>
            );
            return (
                <BrowserRouter>
                    <div>
                        {appHeader}
                        <main className="">{routes}</main>

                        {timeTicker}
                    </div>
                </BrowserRouter>
            );
        }
        //else if (loginDetails[0].isOTPVerified) {
        else if (otpDetails[0].isOTPVerified) {
            console.log("OTP Verified");
            localStorage.setItem("username", loginDetails[0].username);
            var routesPrivacy = (
                <Switch>
                    <Route exact path="/Consent"
                        render={() =>
                            <Consent
                                loginDetails={loginDetails}
                                otpDetails={otpDetails}
                                loginClick={this.clickEventLogin.bind(this)}
                                loginChangeEvent={this.changeEventTextFieldLogin.bind(this)}
                                forgetPasswordClick={this.clickEventForgetPassword.bind(this)}
                                //onChangeRememberMe={this.changeEventRememberMe.bind(this)}
                                rememberMeDetails={rememberMeDetails}
                                loader={isLoading}
                                url={"http://localhost:54008"}

                            />
                        }
                    />
                    <Redirect from="/" to="/Consent" />
                    <Route exact path="/CandidateData" render={(props) =>
                        <HiringForm
                            loginDetails={loginDetails}
                            UserFullName={loginDetails[0].userfirstname + " " + loginDetails[0].userlastname}
                            userId={loginDetails[0].userid}
                            applicantId={loginDetails[0].applicantid}
                            roleId={loginDetails[0].roleId}
                            url={"http://localhost:54008/CandidateData"}
                            loader={isLoading}
                            token={loginDetails[0].authToken}
                        />
                    } />
                    <Route exact path="/HiringFormStatic" render={() =>
                        <HiringFormStatic
                            loginDetails={loginDetails}
                            UserFullName={loginDetails[0].userfirstname + " " + loginDetails[0].userlastname}
                            userId={loginDetails[0].userid}
                            applicantId={loginDetails[0].applicantid}
                            roleId={loginDetails[0].roleId}
                            url={"http://localhost:54008/CandidateData"}
                            loader={isLoading}
                            token={loginDetails[0].authToken}
                        />
                    } />                    
                </Switch>
            );
            return (
                <BrowserRouter>
                    <div>
                        {appHeader}
                        <main className="">{routesPrivacy}</main>
                    </div>
                </BrowserRouter>
            );
        }
        else if (loginDetails[0].LoginFlag === 0 && loginDetails[0].username != '' && loginDetails[0].emailValid === 0) {
            console.log("Unauthorized Access");
            var routesUnauthorized = (
                <Switch>
                    <Route exact path="/UnauthorizedAccess" render={() => <UnauthorizedAccess logoutClick={this.clickEventLogout.bind(this)} />} />
                    <Redirect from="/" to="/UnauthorizedAccess" />
                </Switch>
            );
            return (
                <BrowserRouter>
                    <div>
                        {appHeader}
                        <main className="">{routesUnauthorized}</main>
                        {timeTicker}
                    </div>
                </BrowserRouter>
            );
        }
        else if (loginDetails[0].LoginFlag === 0 && loginDetails[0].username != '' && loginDetails[0].emailValid === 1 && loginDetails[0].passwordValid === 0) {
            console.log('Please enter correct Password');
            loginDetails[0].passwordValid = 1;
            // window.location.href = '/';
            var routesLogin = (
                <Switch>
                    <Route exact path="/" render={() =>
                        <LoginPage
                            loginDetails={loginDetails}
                            loginClick={this.clickEventLogin.bind(this)}
                            loginChangeEvent={this.changeEventTextFieldLogin.bind(this)}
                            forgetPasswordClick={this.clickEventForgetPassword.bind(this)}
                            //onChangeRememberMe={this.changeEventRememberMe.bind(this)}
                            rememberMeDetails={rememberMeDetails}
                        />
                    }
                    />
                    <Redirect from="/" to="/LoginPage" />
                </Switch>
            );
            return (
                <BrowserRouter>
                    <div>
                        {appHeader}
                        <main className="">{routesLogin}</main>
                        {timeTicker}
                    </div>
                </BrowserRouter>
            );
        }
        else if (loginDetails[0].LoginFlag === 1 && loginDetails[0].username != '' && otpDetails[0].isOTPVerified) {
            var routesHiring = (
                <Switch>
                    <Route exact path="/HiringForm" render={(props) =>
                        <HiringForm
                            loginDetails={loginDetails}
                            UserFullName={loginDetails[0].userfirstname + " " + loginDetails[0].userlastname}
                            userId={loginDetails[0].userid}
                            applicantId={loginDetails[0].applicantid}
                            roleId={loginDetails[0].roleId}
                            url={"http://localhost:54008/CandidateData"}
                            loader={isLoading}
                            token={loginDetails[0].authToken}
                        />
                    } />
                    <Route exact path="/HiringFormStatic" render={() =>
                        <HiringFormStatic
                            loginDetails={loginDetails}
                            UserFullName={loginDetails[0].userfirstname + " " + loginDetails[0].userlastname}
                            userId={loginDetails[0].userid}
                            applicantId={loginDetails[0].applicantid}
                            roleId={loginDetails[0].roleId}
                            url={"http://localhost:54008/CandidateData"}
                            loader={isLoading}
                            token={loginDetails[0].authToken}
                        />
                    } />                    
                </Switch>
            );
            return (
                <BrowserRouter>
                    <div>
                        {appHeader}
                        <main className="">{routesHiring}</main>
                        {scrollToTop}
                        {timeTicker}
                    </div>
                </BrowserRouter>
            );
        }
        else {
            //console.log("authorized user");
            const routesAuthorized = (
                <Switch>
                    <Route exact path="/" render={() =>
                        <LoginPage
                            loginDetails={loginDetails}
                            loginClick={this.clickEventLogin.bind(this)}
                            loginChangeEvent={this.changeEventTextFieldLogin.bind(this)}
                            forgetPasswordClick={this.clickEventForgetPassword.bind(this)}
                            //onChangeRememberMe={this.changeEventRememberMe.bind(this)}
                            rememberMeDetails={rememberMeDetails}
                            loader={isLoading}
                        />
                    }
                    />
                </Switch>
            );
            return (
                <BrowserRouter>
                    <div>
                        {appHeader}
                        <main className="">{routesAuthorized}</main>
                        {timeTicker}
                    </div>
                </BrowserRouter>);
        }
    }
}

