import React, { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { TextField, createMuiTheme, ThemeProvider, Typography } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import "../../common/styles/HiringStaticPage.css";

const theme = createMuiTheme({
    overrides: {
        //MuiTextField: {
        //    root: {
        //        color: "#002949",
        //        background: "#000028",
        //        width: '100%'
        //    },
        //},
        MuiFormLabel: {
            root: {
                //color: "#B3B3BE",
                color: "#000028",
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#00CCCC",
                },
            }
        },
        MuiTextField: {
            root: {
                color: "#000028",
                background: "#E8F8F8",
                width: '100%'
            },
        },
        MuiInputLabel: { // Name of the component ⚛️ / style sheet
            root: { // Name of the rule
                //color: "#B3B3BE",
                color: "#4C4C68",
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#00CCCC"
                }
            },
            asterisk: {
                color: "red"
            }
        },
        MuiInputBase: {
            root: {
                color: "#4C4C68"
            },
            input: {
                backgroundColor: "#EBFBFB",
                color: "#4C4C68",
                borderBottom: "0px solid #707070",
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#E0F9F9",
                    backgroundColor: "#CCF5F5",
                },
                "&$hover": {

                    backgroundColor: "#CCF5F5",
                }
            }
        },
        MuiInput: {
            root: {
                height: "56px",
                backgroundColor: "#E8F8F8",
                color: "#000028",
                paddingLeft: "0px",
                "&$focused": {
                    backgroundColor: "#E8F8F8",
                }
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #002949",
                    transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:after": {
                    borderBottom: "2px solid #002949",
                    transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC"
                },
                "&:hover:after": {
                    borderBottom: "2px solid #2EE6E2"
                },
                "&:focused:after": {
                    borderBottom: "2px solid  #2EE6E2"
                },
                "&:disabled:before": {
                    border: "none"
                }
            },
        },
        MuiSelect: {
            root: {
                backgroundColor: "#E8F8F8",
                color: "#000028"
            }
        },
        MuiFilledInput: {
            root: {
                backgroundColor: "#E8F8F8",
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#000028",
                    backgroundColor: "#E8F8F8",
                },
                "&$hover": {
                    backgroundColor: "#E8F8F8"
                },
                "&$disabled": {
                    "pointerEvent": "none",
                    "color": "#707080",
                    "backgroundColor": "#001035",
                    "border": "2px solid #707070",
                    "borderRadius": "0",
                    "&:before": {
                        "borderBottom": "1px solid #001035",
                    },
                    "&:hover:before": {
                        "borderBottom": "1px solid #001035",
                        "borderBottomStyle": "solid"
                    }
                }
            },
            underline: {
                "&:before": {
                    borderBottom: "2px solid #E8F8F8",
                    transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:after": {
                    borderBottom: "2px solid #E8F8F8",
                    transition: "border-bottom-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:hover:before": {
                    borderBottom: "2px solid #00CCCC"
                },
                "&:hover:after": {
                    borderBottom: "2px solid #6CD6D6"
                },
                "&:focused:after": {
                    borderBottom: "2px solid #6CD6D6"
                },
                "&:disabled:before": {
                    border: "none"
                }
            },
        },
        MuiFormControl: {
            root: {
                color: "#FFFFFF",
                width: "100",
                paddingLeft: "0px"
            }
        },
        MuiList: {
            root: {
                backgroundColor: "#CCF5F5"
            }
        },
        MuiListItem: {
            root: {
                color: "#FFFFFF",
            }
        },
        MuiPaper: {
            rounded: {
                border: `2px solid #2EE6E2`,
                marginBottom: "20px",
                background: "#CCF5F5"
            }
        },
        MuiToolbar: {
            root: {
                background: "#000028",
                color: "#FFFFFF"
            }
        },
        MuiTablePagination: {
            root: {
                borderBottom: "none"
            },
            toolbar: {
                borderTop: `1px solid #FFFFFF`,
                "& MuiTypography-root": {
                    color: `#00CCCC !important`
                }
            },
            menuItem: {
                color: "#FFFFFF"
            },
            selectIcon: {
                color: "#00CCCC"
            },
            input: {
                backgroundColor: "#000028"
            }
        },
        MuiTypography: {
            caption: {
                color: "#FFFFFF"
            }
        },
        MuiTableSortLabel: {
            root: {
                color: "#00CCCC",
                "&$focused": {
                    color: "#00CCCC",
                },
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
                "& div:hover": {
                    color: "#00CCCC"
                }
            },
            icon: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
            },
            iconDirectionDesc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
            },
            iconDirectionAsc: {
                color: `#00CCCC !important`,
                "&$hover": {
                    color: "#FFFFFF"
                },
                "&$active": {
                    color: "#00CCCC"
                },
            }
        },
        MuiTableCell: {
            root: {
                color: "#FFFFFF !important"
            },
            body: {
                background: "#000028",
                color: "#FFFFFF"
            }
        },
        //MuiFormControl: {
        //    root: {
        //        paddingLeft: "0px"
        //    }
        //},
        MuiSvgIcon: {
            root: {
                color: "#2EE6E2"
            }
        },
        MuiPickersBasePicker: {
            pickerView: {
                background: "#FFFFFF"
            },
            container: {
                background: ""
            }
        },
        MuiPickersDatePickerRoot: {
            toolbar: {
                background: "#000028"
            }
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: "none",
                color: "#FFFFFF"
            },
            switchHeader: {
                color: "#FFFFFF"
            },
            daysHeader: {
                color: "#FFFFFF"
            },
            dayLabel: {
                color: "#FFFFFF"
            }
        },
        MuiPickersYear: {
            root: {
                color: "#FFFFFF"
            }
        },
        //MuiPickersBasePicker: {
        //    container: {
        //        background: ""
        //    }
        //},
        MuiPickersDay: {
            day: {
                color: "#FFFFFF"
            }
        },
        MuiButton: {
            textPrimary: {
                color: "#FFFFFF"
            }
        },
        MuiTable: {
            root: {
                backgroundColor: "#000028"
            }
        }
    }
});

export default function LoginPage(props) {
    const [formData, setFormData] = useState({ userName: '', password: '' });
    const [userName, setUserName] = useState('');
    //const [password, setPassword] = useState('');
    //const [rememberMeIsChecked, setRememberMeIsChecked] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorTextEmail, setErrorTextEmail] = useState('');

    //const [showPassword, setShowPassword] = useState(false);
    //const handleClickShowPassword = () => setShowPassword(!showPassword);
    //const handleMouseDownShowPassword = () => setShowPassword(!showPassword);

    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState('');
    const [openClassName, setClassName] = useState('');
    const [openType, setOpenType] = useState('');
    //const [openConfirm, setOpenConfirm] = useState(false);
    //const [openAlertConfirm, setOpenAlertConfirm] = useState('');

    const changeEventUsername = (e) => {
        //const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
        //let EmailId = e.target.value;
        //let isvalid = re.test(String(EmailId).toLowerCase());
        //setUserName(EmailId);
        //if (EmailId === undefined || EmailId === "" || !isvalid) {
        //    setOpen(true);
        //    setOpenAlert("Invalid Email");
        //    setClassName("bg-red clr-white");
        //    setOpenType("Alert");
        //    //setUserName("");
        //}
        //else {
        //    isRegistered(e.target.value);            
        //}       
        setUserName(e.target.value);
        setFormData({ ...formData, userName: [e.target.value] });
        ValidateEmail(e.target.value);
    }


    function isRegistered(EmailId) {
        const formData = new FormData();
        formData.append('UserName', EmailId);
        const options = {
            method: 'post',
            //headers: {
            //    'Accept': 'application/json, text/plain, */*',
            //    'Content-Type': 'application/json'
            //},

            body: formData
        }
        fetch('Login/IsRegistered', options)
            .then(response => response.json())
            .then(data => {
                //console.log(data);
                //if (data.isAlreadyRegistered == true) {
                if (data.isAlreadyRegistered) {
                    //alert("You have been already registered!!");
                    // this.setState({ isLoading: false });
                    setOpen(true);
                    //setOpenAlert("You have been already registered!!");
                    setOpenAlert("You have already submitted admission form successfully.");
                    setClassName("bg-green clr-white");
                    setOpenType("Alert");
                }
                else {
                    //setUserName(EmailId);
                    //setFormData({ ...formData, userName: [EmailId] });
                    //props.loginChangeEvent("username", EmailId);
                    props.loginClick();
                }
            }).catch(error => {
                console.log("Error:", error)
                this.setState({ isLoading: false });
            });
    }

    const handleClose = () => {
        setOpen(false);
    };

    //const changeRememberMe = (event) => {
    //    setRememberMeIsChecked(event.target.checked);
    //    props.onChangeRememberMe(event.target.checked);
    //    if (!event.target.checked) {
    //        setUserName(props.loginDetails[0].username);
    //        setPassword('');
    //    }
    //    else {
    //        setUserName(userName);
    //        setPassword(password);
    //    }
    //};
    const submit = () => {

        if (userName != null && userName.trim().length > 0) {
            isRegistered(userName);
            //props.loginClick(props.loginDetails);
            //props.loginClick();
        }


    }

    function ValidateEmail(Email) {
        if (Email == null || Email == '') {
            setErrorEmail(true);
            setErrorTextEmail("Mandatory field");
            return true;
        }
        else {
            const exp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let valid = exp.test(String(Email).toLowerCase());
            if (!valid) {
                setErrorEmail(true);
                setErrorTextEmail("Email format invalid");
                return true;
            }
            else {
                props.loginChangeEvent("username", Email);
                setErrorEmail(false);
                setErrorTextEmail("");
                return false;
            }
        }
    }

    //const forgetPasswordHandler = () => {
    //    props.forgetPasswordClick();
    //}

    const enterKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            props.loginClick();
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', enterKeyDown);
        if (props.rememberMeDetails[0].rememberMeIsChecked && props.rememberMeDetails[0].username !== '') {
            const UserName = props.rememberMeDetails[0].username;
            const Password = props.rememberMeDetails[0].password;
            //setRememberMeIsChecked(props.rememberMeDetails[0].rememberMeIsChecked);
            setUserName(UserName);
            props.loginChangeEvent("username", UserName);
            //setPassword(Password);
            props.loginChangeEvent("password", Password);
        }
        props.loader === true ? setOpen(true) : setOpen(false);

        return () => {
            document.removeEventListener('keydown', enterKeyDown);
        }

    }, [props.rememberMeDetails[0], props.loader]);

    return (
        <ThemeProvider theme={theme}>
            <Grid container spacing={1} align="center" justify="center" className="position-relative">
                <Grid item xs={12} sm={6} className="home-user-description bg-white mt-4">
                    <div className="d-flex fd-column box-shadow bdr-radius align-item-center pd-mobile-10" >
                        <Typography className="mt-1" component="h1" variant="h5">
                            <b> Siemens Technical Academy India </b>
                        </Typography>
                        <Typography className="mt-1" component="h1" variant="h5">
                            <b> Student Admission Form </b>
                        </Typography>
                        <Grid item xs={12} className="mt-2">
                            Welcome to Siemens Technical Academy India Student Admission Form, please provide your Email ID to proceed further.
                         </Grid>
                        <Grid item xs={12} className="mt-1">
                            <p className="loginpageDate">
                                The last date of application is 30th June 2024.
                           </p>
                        </Grid>



                        <Grid item xs={12}>
                            <b>Sign in </b>
                        </Grid>
                        <form noValidate className="p-1 mb-1">
                            <TextField className="lightInputFields" id="gf-statement-userName"
                                label="Email"
                                variant="filled"
                                id="userName"
                                type="search"
                                fullWidth
                                required
                                onChange={changeEventUsername}
                                name="Username"
                                value={userName}
                                error={errorEmail}
                                helperText={errorTextEmail}
                            />

                            <Button variant="contained" className="ns-btn-primary jc-center mt-1" onClick={submit}>
                                <span className="f-14 fw-bold">Get OTP</span>
                            </Button>

                        </form>

                    </div>
                    <p className="f-14 fw-bold">*Please open site in Google Chrome Browser for better user experience</p>
                </Grid>

                <Grid item xs={12} sm={6} className="cognisphere-vid-block" id="CognisphereVid">
                    <video autoPlay loop muted className="width-100 height-100">
                        <source src={"../assets/animation/cognisphere_home.mp4"} type="video/mp4" />
                    </video>
                </Grid>
                <Dialog
                    open={open}
                    //onClose={handleClose}
                    aria-labelledby="draggable-dialog-title"
                    id="templateUploadSuccess"
                    PaperProps={{
                        style: {
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            borderColor: "transparent"
                        },
                    }}
                >
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="draggable-dialog-title"
                    id="templateUploadSuccess">
                    <DialogTitle className={openClassName} style={{ cursor: 'move', background: '#00B3B3', }} id="draggable-dialog-title">
                        {openType}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText className="clr-dark-blue">
                            {openAlert}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="bg-content">
                        <Button variant="contained" autoFocus onClick={handleClose} className="ns-btn-secondary-light">
                            <span className="f-16 fw-bold clr-white">OK</span>
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </ThemeProvider>
    );
}
